<template>
  <div>
    <v-container class="fill-height page-login messaging pa-0" fluid>
      <v-row>
        <v-col>
          <v-card class="pa-3 page-login__card" tile>
            <v-card-title>
              <img src="/static/m.png" alt="มูลนิธิแม่ฟ้าหลวง" width="55" />
              <h2 class="primary--text display-1">
                แบบประเมินผลการศึกษาดูงาน<br />มูลนิธิแม่ฟ้าหลวงฯ
              </h2>
            </v-card-title>
            <v-card-text>
              <el-form>
                <!-- choose group -->
                <v-select
                  v-if="showGroupList"
                  loading="groupListLoading"
                  :items="trip.items"
                  :item-text="'group'"
                  :item-value="'group_id'"
                  label="กรุณาเลือกคณะของคุณ"
                  v-model="trip.select"
                  v-on:change="handleChangeGroupList(`${trip.select}`)"
                ></v-select>
                <!-- alert message -->
                <v-alert
                  v-if="error_message"
                  outlined
                  type="warning"
                  color="warning"
                  prominent
                  border="left"
                >
                  {{ error_message }}
                </v-alert>
                <el-collapse accordion v-model="activeNames">
                  <el-collapse-item
                    :title="eachFormObj.title"
                    :name="eachFormIndex"
                    :key="eachFormIndex"
                    v-for="(eachFormObj, eachFormIndex) in formData.data"
                  >
                    <template slot="title">
                      <h2>{{ eachFormObj.title }}</h2>
                    </template>
                    <el-row :key="`row-${eachFormIndex}`">
                      <div class="wrapper--forms">
                        <el-col
                          v-for="(field, index) in eachFormObj.fields"
                          :key="index"
                          :span="field.span"
                          v-bind="field"
                          class="form__group"
                        >
                          <!-- <label class="form__label" v-model="form.label" v-show="form.hasOwnProperty('label')">{{ form.label }}</label> -->
                          <component
                            :is="field.fieldType"
                            :currentField="field"
                            @onSkipTo="handleSkipTo"
                            class="form__field"
                          >
                          </component>
                          <small
                            class="form__helpblock"
                            v-show="eachFormObj.isHelpBlockVisible"
                          >
                            {{ eachFormObj.helpBlockText }}
                          </small>
                        </el-col>
                      </div>
                      <el-col>
                        <el-button
                          type="primary"
                          @click="handleNext"
                          v-if="eachFormIndex < formData.data.length - 1"
                          >ถัดไป</el-button
                        >
                        <el-button
                          type="primary"
                          :loading="loading"
                          v-if="activeNames == formData.data.length - 1"
                          @click="handleSubmit"
                          >บันทึก</el-button
                        >
                      </el-col>
                    </el-row>
                  </el-collapse-item>
                </el-collapse>

                <!-- <el-button
                type="primary"
                :v-model="hasNextStep"
                :loading="loading"
                @click="handleNext"
                >ถัดไป</el-button
              > -->
              </el-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style>
.el-form-item__label {
  text-align: left;
  line-height: 25px;
}
</style>
<script>
import { FormBuilder } from '@/components/form_elements/formbuilder'
import { getFormById, getFormByTripId } from '@/api/form'
import { getTripById, getActiveTrip } from '@/api/trip'

import {
  getAssessmentByUUID,
  setAssessment,
  updateAssessment
} from '@/api/assessment'

import { mapGetters } from 'vuex'
// import lodash from 'lodash'
export default {
  name: 'Assessment',
  components: {
    ...FormBuilder.$options.components
  },
  data() {
    return {
      error_message: '',
      valueModel: [],
      themingVars: '',
      loading: true,
      formData: {
        data: []
      },
      showGroupList: false,
      trip: {
        select: {},
        items: []
      },
      activityForm: [],
      assessment: null,
      user: {},
      uuid: '',
      tripId: '',
      formId: '',
      hasNextStep: false,
      activeNames: 0,
      activeForm: null
    }
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'isAcceptPolicy',
      'getIdentity',
      'getLoginUser',
      'getToken',
      'getUUID'
    ])
    // ...mapActions(['createUUID'])
    // ...mapState({
    //   // forms: (state) => state.formElements.forms
    // })
  },
  async created() {
    // if(tripId)
    const tripId = this.$route.params.tripId
    const formId = this.$route.params.formId

    // const groupId = this.$route.params.groupId

    if (!tripId) {
      // ไม่ระบุ form id redirect ไปยังหน้า list ประเมิน
      // this.$router.push(`/evaluation/choose`)
    }

    // Check Accept policy
    if (!this.isAcceptPolicy) {
      this.$router.push(`/assessment/welcome/${tripId}`)
    }

    if (tripId) {
      this.showGroupList = false
      this.tripId = tripId
      this.getFormData(this.tripId)
    } else if (formId) {
      this.formId = formId
      this.getTripData()
    }
    // get from id

    // getLoginUser & user identity
    let { loginUser, uuid } = this.getIdentity
    // console.log({ loginUser, uuid })
    if (loginUser) {
      this.loginUser = loginUser
      this.uuid = loginUser.uuid
    } else if (uuid) {
      this.uuid = uuid
    } else {
      uuid = await this.$store.dispatch('fetchUUID')
      this.uuid = uuid
    }
    // elseif(uuid){
    //   this.uuid = uuid
    // }else(!loginUser && !uuid){
    //   uuid = this.$store.dispatch('fetchUUID')
    //   this.uuid = uuid
    // }
    // check accept policy

    // get trip List

    // แสดงแบบประเมิน
  },

  methods: {
    handleChangeGroupList(groupId) {
      const trip = this.trip.items.find((item) => {
        return (item.group_id = groupId)
      })
      if (trip) {
        this.tripId = trip.id
        getFormById(this.formId).then((form) => {
          this.formData = form
        })
      }
    },
    handleNext() {
      this.activeNames++
    },
    handleSkipTo(title) {
      const sectionIndex = this.formData.data.findIndex((item) => {
        return item.title == title
      })
      if (sectionIndex != null) {
        this.activeNames = sectionIndex
      }
    },
    handleSubmit() {
      let data = {
        uuid: this.getUUID,
        trip_id: this.formData.trip_id,
        courses_id: this.formData.course_id,
        activity_id: this.formData.activity_id,
        is_success: true,
        data: this.formData.data
      }
      this.loading = true
      let callApiAssessment
      if (this.assessment) {
        callApiAssessment = updateAssessment(this.assessment.id, data)
      } else {
        let uuid = this.getUUID
        if (!uuid) {
          uuid = new Date().getTime()
        }
        callApiAssessment = setAssessment(this.tripId, uuid, data)
      }
      // console.log(this.formData)
      callApiAssessment
        .then(() => {
          this.$notify({
            type: 'success',
            group: 'notify',
            title: 'บันทึกข้อมูลเรียบร้อย'
            // text: data.message
          })
          // this.assessment = assessment
          if (this.hasLastActivityForm()) {
            alert('บันทึกข้อมูลเรียบร้อยแล้ว')
            this.$router.push('/assessment/complete')
          } else {
            this.nextActivityForm()
          }
        })
        .catch(() => {
          // this.$notify({
          //   type: 'error',
          //   group: 'notify',
          //   title: 'เกิดข้อผิดพลาด',
          //   text: error.message
          // })
        })
        .finally(() => {
          this.loading = false
        })
    },

    filterActivityEnable() {
      let activities = []
      const enabled = this.trip.activity_enable

      enabled.forEach((activity_id) => {
        const formItem = this.activityForm.find((item) => {
          return item.activity_id == activity_id
        })
        if (formItem) {
          activities.push(formItem)
        }
      })
      this.activityForm = activities
    },
    filterActivityOpen() {
      const opened = this.trip.activity_open
      this.activityForm = this.activityForm.filter((item) => {
        return opened.includes(item.activity_id)
      })
    },
    hasLastActivityForm() {
      return this.activeForm + 1 >= this.activityForm.length
    },
    setActiveForm(formIndex) {
      if (formIndex >= this.activityForm.length) {
        console.error(`Missing activity form index ${this.activeForm}`)
        return false
      }
      if (!this.activityForm[formIndex]) {
        console.error(`Missing activity form index ${this.activeForm}`)
        return false
      }
      this.activeNames = 0
      this.activeForm = formIndex
      this.formData = this.activityForm[formIndex]
      return true
    },
    nextActivityForm() {
      return this.setActiveForm(this.activeForm + 1)
    },
    getTripData() {
      this.loading = true
      this.groupListLoading = true
      getActiveTrip()
        .then((trips) => {
          this.trip.items = trips
        })
        .catch(() => {
          const msg = `ไม่พบข้อมูล ฟอร์มการประเมินของท่าน กรุณาติดต่อเจ้าหน้าที่ error [4071]`
          this.$notify({
            type: 'error',
            group: 'notify',
            title: 'ไม่พบข้อมูลการ',
            text: msg
          })
          this.error_message = msg
        })
        .finally(() => {
          this.loading = false
          this.groupListLoading = false
          this.showGroupList = true
        })
    },
    getFormData(tripId) {
      this.loading = true
      return (
        getTripById(tripId)
          .then((trip) => {
            console.log(trip)
            this.trip = trip
            return getFormByTripId(tripId)
          })
          .then((activityForm) => {
            this.activityForm = activityForm
            this.filterActivityEnable()
            this.filterActivityOpen()
            this.setActiveForm(0)
            if (this.uuid) {
              return getAssessmentByUUID(this.tripId, this.uuid)
            }
            return
          })
          // .then((assessment) => {
          //   this.assessment = assessment
          //   // this.formData.data = assessment.data
          // })
          .catch((err) => {
            console.error(err)
            this.$notify({
              type: 'error',
              group: 'notify',
              title: 'ไม่พบข้อมูลการ',
              text: err.message
            })
            this.error_message = err.message
          })
          .finally(() => {
            this.loading = false
          })
      )
    }
    // getAssessmentData() {
    //   getAssessmentByUUID(this.tripId, this.uuid)
    //     .then((assessment) => {
    //       if (assessment) {
    //         this.assessment = assessment
    //         this.formData.data = assessment.data
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(new Error(error))
    //     })
    //     .finally(() => {
    //       this.loading = false
    //     })
    // }
  }
}
</script>
