const AssessmentsItem = {
  id: '',
  user_id: '',
  uuid: '',
  trip_id: '',
  courses_id: '',
  activity_id: '',
  data: '',
  is_success: '',
  create_by: '',
  create_date: ''
}
const Items = []

import { fetch, fetchGet } from './fetch'
const url = '/api/v1/assessment'
const defaultCatch = (error) => {
  return Promise.reject(error)
}
const defaultSuccess = (assessment) => {
  return Promise.resolve(assessment)
}
const getAssessment = (id) => {
  if (id === undefined) {
    return Promise.reject(new Error('Missing form id'))
  }
  const urlById = `${url}/${id}`
  return fetchGet(urlById, {})
    .then(defaultSuccess)
    .catch(defaultCatch)
}

const getAssessmentByUUID = (tripId, uuid) => {
  if (uuid === undefined) {
    return Promise.reject(new Error('Missing form uuid'))
  }

  const apiUrl = `${url}/trip/${tripId}/uuid/${uuid}`
  return fetchGet(apiUrl, {})
    .then(defaultSuccess)
    .catch(defaultCatch)
}

const getReportByTrip = (tripId) => {
  const apiUrl = `${url}/report/trip/${tripId}`
  return fetchGet(apiUrl, {})
    .then(defaultSuccess)
    .catch(defaultCatch)
}

const getReportDataTable = (sourceData) => {
  const data = sourceData.map((item) => {
    return item
  })
  return data
}
const getReportGraphBar = (sourceData) => {
  const data = sourceData.map((item) => {
    return item
  })
  return data
}

const setAssessment = (tripId, uuid, data) => {
  const apiUrl = `${url}/new/trip/${tripId}/uuid/${uuid}`
  return fetch(apiUrl, data)
    .then(defaultSuccess)
    .catch(defaultCatch)
}
const updateAssessment = (assessmentId, data) => {
  const apiUrl = `${url}/${assessmentId}/update`
  return fetch(apiUrl, data)
    .then(defaultSuccess)
    .catch(defaultCatch)
}

export {
  AssessmentsItem,
  Items,
  getAssessment,
  getAssessmentByUUID,
  getReportByTrip,
  getReportDataTable,
  getReportGraphBar,
  setAssessment,
  updateAssessment
}
